<template>
  <TextInput
    v-if="disabled"
    :label="label"
    name="name"
    :error="error"
    :model-value="convertedDate"
    :required="required"
    :disabled="true"
  />
  <div v-else class="flex flex-col space-y-1 mb-5">
    <InputLabel v-if="label" :id="id || name" :required="required">
      {{ label }}
    </InputLabel>
    <div class="flex flex-col space-y-0.5">
      <div class="relative">
        <input type="hidden" :name="name" :value="convertedDate" data-testid="hidden-input" />
        <Datepicker
          ref="input"
          v-model="date"
          :title="title"
          :placeholder="placeholder"
          :flow="flow"
          :input-id="id || name"
          :time-picker="type === 'time'"
          :enable-time-picker="type !== 'date' || enableTimePicker"
          :min-date="jsMinDate"
          :max-date="jsMaxDate"
          :minutes-increment="5"
          :start-time="{ hours: 20, minutes: 0, seconds: 0 }"
          :disabled-dates="disabledDates"
          :disabled-week-days="disabledWeekDays"
          :format="format"
          auto-position
          :input-class-name="error ? 'border-red-300' : ''"
          :locale="userLang"
          :select-text="$t('forms.datepicker.select')"
          :cancel-text="$t('forms.datepicker.cancel')"
          class="vue3-datepicker text-sm font-medium"
        />
        <div class="absolute -right-6 h-full top-0 flex items-center">
          <div class="absolute right-8 flex space-x-1 items-center">
            <ClockIcon v-if="type === 'time'" class="h-6 w-6 text-gray-500 pointer-events-none" />
            <CalendarIcon v-else class="h-6 w-6 text-gray-500 pointer-events-none" />
            <ExclamationCircleSolidIcon v-if="error" class="h-5 w-5 text-red-300" />
          </div>
          <tooltip-button v-if="tooltip" :tooltip="tooltip"></tooltip-button>
        </div>
      </div>
      <error-message v-if="showErrorText && error">{{ error }}</error-message>
      <HelpMessage v-if="helpText">{{ helpText }}</HelpMessage>
    </div>
  </div>
</template>

<script>
import { DateTime, Settings } from "luxon"
import Datepicker from "@vuepic/vue-datepicker"
import { dateTimeFormat, userLang } from "@/helpers/globals"
// css file is imported manually in date-picker.scss
import InputLabel from "@/components/elements/inputs/_base/InputLabel.vue"
import ErrorMessage from "@/components/forms/_base/ErrorMessage.vue"
import TooltipButton from "@/components/forms/_base/TooltipButton.vue"
import HelpMessage from "@/components/elements/inputs/_base/HelpMessage.vue"
import { CalendarIcon, ClockIcon, ExclamationCircleSolidIcon } from "@/components/icons"
import TextInput from "../elements/inputs/TextInput.vue"
Settings.defaultLocale = document.documentElement.lang.substr(0, 2)
export default {
  components: {
    ClockIcon,
    TextInput,
    Datepicker,
    InputLabel,
    HelpMessage,
    ErrorMessage,
    CalendarIcon,
    TooltipButton,
    ExclamationCircleSolidIcon,
  },
  props: {
    name: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    modelValue: {
      type: [String, Number],
      default: null,
    },
    error: {
      type: [String, Boolean],
      default: false,
    },
    helpText: String,
    showErrorText: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    minDate: {
      type: String,
      default: null,
    },
    maxDate: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: "datetime",
    },
    flow: {
      type: Array,
      default: null,
    },
    format: {
      type: String,
      default: () => dateTimeFormat.dateTime,
    },
    title: {
      type: String,
      default: () => null,
    },
    tooltip: {
      type: String,
      default: () => null,
    },
    isISOString: {
      type: Boolean,
      default: false,
    },
    enableTimePicker: Boolean,
    disabledDates: Array,
    disabledWeekDays: Array,
    disabled: Boolean,
  },
  emits: ["update:modelValue", "input"],
  setup() {
    return { userLang }
  },
  data() {
    return {
      date: "",
    }
  },
  computed: {
    convertedDate() {
      if (this.date) {
        return this.type === "time"
          ? DateTime.fromObject(this.date).toFormat(this.format)
          : DateTime.fromJSDate(this.date).toFormat(this.format)
      }
      return null
    },
    jsMinDate() {
      return this.minDate ? DateTime.fromFormat(this.minDate, this.format).toJSDate() : null
    },
    jsMaxDate() {
      return this.maxDate ? DateTime.fromFormat(this.maxDate, this.format).toJSDate() : null
    },
  },
  watch: {
    date(val) {
      if (val) {
        this.$emit("input", this.convertedDate)
        this.$emit("update:modelValue", this.convertedDate)
      }
    },
    modelValue(newValue) {
      // update the date if prop is updated from parent
      if (!this.convertedDate || newValue !== this.convertedDate.toString()) {
        this.setDate()
      }
    },
  },
  created() {
    this.setDate()
  },
  methods: {
    setDate() {
      this.date = ""
      if (this.modelValue) {
        try {
          const dt = this.isISOString
            ? DateTime.fromISO(this.modelValue)
            : DateTime.fromFormat(this.modelValue, this.format)
          if (this.type === "time") {
            const dateObj = dt.toObject()
            this.date = {
              hours: dateObj.hour,
              minutes: dateObj.minute,
              seconds: dateObj.second,
            }
          } else {
            this.date = dt.toJSDate()
          }
        } catch (err) {
          console.log(err)
        }
      }
    },
  },
}
</script>
